import React from 'react'
import MainBanner from '../AboutOne/MainBanner'
import About from '../AboutOne/About'
import GoTop from '../Shared/GoTop'
import Footer from '../Common/Footer'
import lax from 'lax.js'

class AboutOne extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }
  render() {
    return (
      <React.Fragment>
        {/* Main Banner */}
        <MainBanner />
        {/* About Area */}
        <About />

        <Footer />

        {/* Back Top top */}
        <GoTop scrollStepInPx='50' delayInMs='16.66' />
      </React.Fragment>
    )
  }
}

export default AboutOne
