import React from 'react'
import lax from 'lax.js'
import OwlCarousel from 'react-owl-carousel3'
import PlatinumSponsors from '../LaxButton/PlatinumSponsors'

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: ["<i class='icofont-rounded-left'></i>", "<i class='icofont-rounded-right'></i>"],
  responsive: {
    0: {
      items: 2,
    },
    400: {
      items: 3,
    },
    576: {
      items: 3,
    },
    768: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
}

class Partner extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }

  render() {
    const sponsor = [
      {
        url: 'https://docs.google.com/forms/d/e/1FAIpQLSe4Y9il8hxFwwd1YwCBQoNd1-uJb-yHjK8OBKqXJCBRYh020Q/viewform',
        imgSrc: require('../../assets/images/91app.png'),
      },
      {
        url: 'https://recruit.cathayholdings.com/CathaybkHR/servlet/HttpDispatcher/EZA0_0000/index',
        imgSrc: require('../../assets/images/cathay.png'),
      },
      { url: '#', imgSrc: require('../../assets/images/cyberlink.jpg') },
      { url: '#', imgSrc: require('../../assets/images/d8ai.png') },
      { url: '#', imgSrc: require('../../assets/images/eland.png') },
      { url: '#', imgSrc: require('../../assets/images/esun.jpg') },
      { url: 'https://www.hpe.com/tw/zh/about.html', imgSrc: require('../../assets/images/HPE.jpg') },
      { url: 'https://bit.ly/3ckTVtD', imgSrc: require('../../assets/images/iKala.jpg') },
      { url: '#', imgSrc: require('../../assets/images/NEC.png') },
      { url: '#', imgSrc: require('../../assets/images/ntum.png') },
      { url: '#', imgSrc: require('../../assets/images/ntuimaa.jpg') },
      { url: '#', imgSrc: require('../../assets/images/oceanblue.png') },
      { url: '#', imgSrc: require('../../assets/images/perfect.png') },
      { url: '#', imgSrc: require('../../assets/images/TSMC.png') },
      { url: '#', imgSrc: require('../../assets/images/upyoung.png') },
      { url: 'https://eagility.us/home/', imgSrc: require('../../assets/images/ycuh.jpg') },
    ]
    return (
      <section className='partner-area ptb-120'>
        <div className='container'>
          <div className='section-title'>
            <span></span>
            <h2>
              贊助<b>廠商</b>
            </h2>

            <div className='bar'></div>
          </div>

          <div className='row align-items-center'>
            <div className='col-lg-12'>
              <div className='partner-title platinum-sponsor'>
                <PlatinumSponsors />
              </div>
            </div>

            {sponsor.map((s) => (
              <div className='col-12 my-3 my-sm-0 col-sm-4 col-lg-3 text-center'>
                <a href={s.url} target={s.url === '#' ? '_self' : '_blank'} rel='noopener noreferrer'>
                  <img className='sponsor-img ms-auto me-auto px-5 p-sm-0' src={s.imgSrc} alt='Partner Logo' />
                </a>
              </div>
            ))}

            <div className='col-lg-12'>
              <div className='border'></div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Partner
