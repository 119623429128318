import React from 'react'
import lax from 'lax.js'
import { Link } from 'react-router-dom'

class About extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }

  render() {
    return (
      <section className='about-area ptb-120 bg-image'>
        <div className='container'>
          <div className='row h-100 align-items-center'>
            <div className='col-lg-6'>
              <div className='about-content'>
                <span>還在等什麼？</span>
                <h2>
                  現在正是時候回到<b>母校</b>與老師、朋友相見
                </h2>
                <p>
                  經過多少年了，自從畢業以後是否曾經問過自己，在臺大資管系就學的期間遇到了多少良師益友？自己是否偶爾會懷念起老師們的諄諄教誨、同學們的幹話連篇？現在趕緊抓緊
                  30 週年系慶的機會，與大家重返母系相聚一起！
                </p>

                <p>
                  今年臺大資管系 30
                  週年系慶是歷屆以來最龐大、動用最多同學籌備的一次系慶，資管系全體教職員誠摯的歡迎畢業系友踴躍的報名參加本次
                  30 週年系慶，可以相揪同學一起包桌報名，也可以單獨報名，回味大學、研究所生活。
                </p>

                <Link to='/schedule' className='btn btn-primary'>
                  查看節目表
                  <i className='icofont-double-right'></i>
                </Link>

                <Link to='/signup' className='btn btn-secondary'>
                  立即報名
                </Link>
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='about-image'>
                <img src={require('../../assets/images/about2.jpg')} className='about-img1' alt='about' />

                <img src={require('../../assets/images/about1.jpg')} className='about-img2' alt='about' />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About
