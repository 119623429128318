import React from 'react'

class About extends React.Component {
  render() {
    return (
      <section className='about-area ptb-120 bg-image'>
        <div className='container'>
          <div className='row h-100 align-items-center'>
            <div className='col-lg-12'>
              <div className='about-content'>
                <span>歡迎捐款</span>
                <h2>
                  <b>臺大資管</b>的下個三十年，願你我一起同行
                </h2>

                <p>
                  時光荏苒，轉眼間，各位系友離開校園踏入社會已有一段日子，各自在自己的領域上勇敢逐夢、嶄露頭角、迎風翱翔。
                </p>

                <p>
                  臺大資管系自1991年成立至今，即將邁入三十週年。回顧過去，系上老師們認真地投入教學及研究工作，也培育了許多資訊管理的卓越人才。面向未來，我們還有很多事務尚待推動：我們希望獎勵大學部學生參加各項學術競賽，以培養多元能力；我們希望補助研究生出國參加國際學術研討會，以增加國際視野；我們希望整修日趨老舊的管五，以提供學生一個更舒適的學習與研討環境；我們希望成立博士生獎學金，以培育具國際競爭力的研究人才：我們希望提供新進教師彈性薪資獎勵，以吸引更多年輕學者返台任教等等。然而受限於系上非常有限的經費，以及政府預算使用上的限制，我們只能緩慢地前進。
                </p>

                <p>
                  各位親愛的系友們，臺大資管有我們共同的回憶與驕傲，臺大資管的持續發展需要您的熱誠支持！誠摯地期盼各位系友慷慨捐款，支持系上持續追求進步以及卓越的企圖，讓臺大資管的學弟妹們能夠繼續勇敢逐夢！您的每一筆捐款，都是臺大資管繼續成長茁壯的力量！
                </p>

                <div className='signature'>
                  <p className='d-inline align-bottom'>臺大資訊管理學系系主任 </p>
                  <img src={require('../../assets/images/signature.png')} alt='signature' />
                </div>

                <a
                  href='https://docs.google.com/forms/d/e/1FAIpQLScBuo1ZUZDc7uFjITVqVL96wjnNK81KUKUW44uxWlnvZOi5dA/viewform?usp=sf_link'
                  className='btn btn-primary'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  立即捐款
                  <i className='icofont-double-right'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About
