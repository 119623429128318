import React from 'react'
import { Link } from 'react-router-dom'

class MainBanner extends React.Component {
  render() {
    return (
      <div className='page-title-area item-bg1'>
        <div className='container'>
          <h1>立即報名</h1>
          <span>謝謝大家的參與，報名桌數已滿，報名系統關閉</span>
          <ul>
            <li>
              <Link to='/'>首頁</Link>
            </li>
            <li>報名方案</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default MainBanner
