import React from 'react'
import MainBanner from '../HomeDefault/MainBanner'
import About from '../HomeDefault/About'
// import WhyUs from '../Common/WhyUs'
// import Speakers from '../HomeDefault/Speakers'
import GoTop from '../Shared/GoTop'
import Footer from '../Common/Footer'
import EventSchedules from '../HomeDefault/EventSchedules'
// import FunFact from '../Common/FunFact'
import lax from 'lax.js'
import Partner from '../Common/Partner'
import LatestNews from '../HomeDefault/LatestNews'
// import BuyTicket from '../Common/BuyTicket'
// import Subscribe from '../Common/Subscribe'

class HomeDefault extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }
  render() {
    return (
      <React.Fragment>
        {/* Main Banner */}
        <MainBanner />
        {/* Blog Area */}
        <LatestNews />
        {/* Why Choose Us Area */}
        {/* <WhyUs /> */}
        {/* About Area */}
        <About />
        {/* Speakers Area */}
        {/* <Speakers /> */}
        {/* Schedule Area */}
        <EventSchedules />
        {/* FunFacts Area */}
        {/* <FunFact /> */}
        {/* Partner Area */}
        <Partner />
        {/* Buy Tickets Area */}
        {/* <BuyTicket /> */}
        {/* Subscribe Area */}
        {/* <Subscribe /> */}

        <Footer />

        {/* Back Top top */}
        <GoTop scrollStepInPx='50' delayInMs='16.66' />
      </React.Fragment>
    )
  }
}

export default HomeDefault
