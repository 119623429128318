import React from 'react'
import { Link } from 'react-router-dom'
import lax from 'lax.js'
import PlatinumSponsors from '../LaxButton/PlatinumSponsors'
import Footer from '../Common/Footer'
class Sponsor extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }
  render() {
    const sponsor = [
      {
        url: 'https://docs.google.com/forms/d/e/1FAIpQLSe4Y9il8hxFwwd1YwCBQoNd1-uJb-yHjK8OBKqXJCBRYh020Q/viewform',
        imgSrc: require('../../assets/images/91app.png'),
      },
      {
        url: 'https://recruit.cathayholdings.com/CathaybkHR/servlet/HttpDispatcher/EZA0_0000/index',
        imgSrc: require('../../assets/images/cathay.png'),
      },
      { url: '#', imgSrc: require('../../assets/images/cyberlink.jpg') },
      { url: '#', imgSrc: require('../../assets/images/d8ai.png') },
      { url: '#', imgSrc: require('../../assets/images/eland.png') },
      { url: '#', imgSrc: require('../../assets/images/esun.jpg') },
      { url: 'https://www.hpe.com/tw/zh/about.html', imgSrc: require('../../assets/images/HPE.jpg') },
      { url: 'https://bit.ly/3ckTVtD', imgSrc: require('../../assets/images/iKala.jpg') },
      { url: '#', imgSrc: require('../../assets/images/NEC.png') },
      { url: '#', imgSrc: require('../../assets/images/ntum.png') },
      { url: '#', imgSrc: require('../../assets/images/ntuimaa.jpg') },
      { url: '#', imgSrc: require('../../assets/images/oceanblue.png') },
      { url: '#', imgSrc: require('../../assets/images/perfect.png') },
      { url: '#', imgSrc: require('../../assets/images/TSMC.png') },
      { url: '#', imgSrc: require('../../assets/images/upyoung.png') },
      { url: 'https://eagility.us/home/', imgSrc: require('../../assets/images/ycuh.jpg') },
    ]
    return (
      <React.Fragment>
        <div className='page-title-area item-bg1'>
          <div className='container'>
            <h1>贊助廠商</h1>
            <span></span>
            <ul>
              <li>
                <Link to='/'>首頁</Link>
              </li>
              <li>贊助廠商</li>
            </ul>
          </div>
        </div>

        <section className='partner-area ptb-120'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-12'>
                <div className='partner-title platinum-sponsor'>
                  <PlatinumSponsors />
                </div>
              </div>

              {sponsor.map((s) => (
                <div className='col-12 my-3 my-sm-0 col-sm-4 col-lg-3 text-center'>
                  <a href={s.url} target={s.url === '#' ? '_self' : '_blank'} rel='noopener noreferrer'>
                    <img className='sponsor-img ms-auto me-auto px-5 p-sm-0' src={s.imgSrc} alt='Partner Logo' />
                  </a>
                </div>
              ))}

              <div className='col-lg-12'>
                <div className='border'></div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Sponsor
