import React from 'react'
import { Link } from 'react-router-dom'

class MainBanner extends React.Component {
  render() {
    return (
      <div className='page-title-area item-bg1'>
        <div className='container'>
          <h1>捐款</h1>
          <span></span>
          <ul>
            <li>
              <Link to='/'>首頁</Link>
            </li>
            <li>捐款</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default MainBanner
