import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Common/Footer'
import seatImg from '../../assets/images/seat.png'

class Seat extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className='page-title-area item-bg1'>
          <div className='container'>
            <h1>座位表</h1>
            <span></span>
            <ul>
              <li>
                <Link to='/'>首頁</Link>
              </li>
              <li>座位表</li>
            </ul>
          </div>
        </div>

        <section className='partner-area ptb-120'>
          <div className='container'>
            <div className='row align-items-center text-center'>
              <div className='col-lg-12'>
                <img src={seatImg} alt='seat' />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Seat
