import React from 'react'
import { Link, withRouter, NavLink } from 'react-router-dom'
import logo from '../../assets/images/logo.png'

class Navigation extends React.Component {
  state = {
    collapsed: true,
    isOpen: false,
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  componentDidMount() {
    let elementId = document.getElementById('navbar')
    document.addEventListener('scroll', () => {
      if (window.scrollY > 170) {
        elementId.classList.add('is-sticky')
        window.history.pushState('', document.title, window.location.pathname)
      } else {
        elementId.classList.remove('is-sticky')
      }
    })
    window.scrollTo(0, 0)
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen })

  componentDidUpdate(nextProps) {
    if (this.props.match.path !== nextProps.match.path) {
      // this.onRouteChanged();
      console.log('OK')
    }
  }

  onRouteChanged = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { collapsed } = this.state
    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show'
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right'
    return (
      <header id='header' className='header-area'>
        <div id='navbar' className='elkevent-nav'>
          <nav className='navbar navbar-expand-md navbar-light'>
            <div className='container'>
              <button
                onClick={this.toggleNavbar}
                className={classTwo}
                type='button'
                data-toggle='collapse'
                data-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon'></span>
              </button>

              <div className={classOne} id='navbarSupportedContent'>
                <ul className='navbar-nav ms-auto'>
                  <li className='nav-item'>
                    <Link exact='true' to='/' onClick={this.toggleOpen} className='nav-link'>
                      首頁
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link exact='true' to='/signup' onClick={this.toggleOpen} className='nav-link'>
                      報名
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link exact='true' to='/schedule' onClick={this.toggleOpen} className='nav-link'>
                      節目表
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <NavLink to='/donate' className='nav-link' onClick={this.toggleNavbar}>
                      捐款
                    </NavLink>
                  </li>

                  <li className='nav-item'>
                    <NavLink to='/sponsors' className='nav-link' onClick={this.toggleNavbar}>
                      贊助商
                    </NavLink>
                  </li>

                  <li className='nav-item'>
                    <NavLink to='/signup' className='nav-link' onClick={this.toggleNavbar}>
                      立即報名
                    </NavLink>
                  </li>

                  <li className='nav-item'>
                    <a
                      href='https://forms.gle/PWSmvWKCap9X6Dds6'
                      className='nav-link'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      限時報名
                    </a>
                  </li>
                </ul>

                <div className='others-option'>
                  <ul>
                    <li>
                      <NavLink to='/seat' className='btn btn-primary' onClick={this.toggleNavbar}>
                        座位表
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <Link className='navbar-brand' to='/'>
                <img src={logo} alt='logo' />
              </Link>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

export default withRouter(Navigation)
