import React from 'react'

class PricingCardOne extends React.Component {
  render() {
    return (
      <section className='pricing-area ptb-120 bg-image'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='pricing-table-box'>
                <div className='pricingTable-header'>
                  <h3 className='title'>早鳥優惠</h3>
                  <div className='price-value'>
                    700<sup>元/人</sup>
                  </div>
                </div>

                <ul className='pricing-content'>
                  <li>期間限定即日起 ~ 12/25</li>
                  <li>包含晚宴、系慶特刊、紀念品</li>
                  <li>攜眷報名，請填眷屬人數，孩童12歲以上要收費、未滿12歲免費</li>
                </ul>

                <a
                  href='https://docs.google.com/forms/d/e/1FAIpQLScB52fzcXtShW_qA5zEMU9RuZ1D5BSPUjGMWMVW_Hoj3B23ZQ/viewform?usp=pp_url&entry.1494265983=%E6%97%A9%E9%B3%A5%E5%84%AA%E6%83%A0%EF%BC%8812/25%E5%89%8D%EF%BC%89&entry.1102459671=700'
                  className='btn btn-primary'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  立即報名
                </a>
              </div>
            </div>

            <div className='col-lg-4 col-md-6'>
              <div className='pricing-table-box'>
                <div className='pricingTable-header'>
                  <h3 className='title'>團體包桌</h3>
                  <div className='price-value'>
                    6000<sup>元/桌</sup>
                  </div>
                </div>

                <ul className='pricing-content'>
                  <li>期間限定即日起 ~ 12/25</li>
                  <li>一桌可容納10人</li>
                  <li>包含晚宴、系慶特刊、紀念品</li>
                  <li>填表單時，桌長選擇桌長身份並付款</li>
                  <li>填表單時，受邀人選擇受邀人身份報名，無需付款</li>
                  <li>
                    攜眷報名，請填眷屬人數，孩童12歲以上要收費、未滿12歲免費，若內含不占位幼童，則不列入每桌10人計算
                  </li>
                </ul>

                <a
                  href='https://docs.google.com/forms/d/e/1FAIpQLScB52fzcXtShW_qA5zEMU9RuZ1D5BSPUjGMWMVW_Hoj3B23ZQ/viewform?usp=pp_url&entry.1494265983=%E5%9C%98%E5%A0%B1%E5%8C%85%E6%A1%8C'
                  className='btn btn-primary'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  立即報名
                </a>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 offset-lg-0 offset-md-3'>
              <div className='pricing-table-box'>
                <div className='pricingTable-header'>
                  <h3 className='title'>一般報名</h3>
                  <div className='price-value'>
                    900<sup>元/人</sup>
                  </div>
                </div>

                <ul className='pricing-content'>
                  <li>報名時間：12/26 ~ 1/7</li>
                  <li>包含晚宴、系慶特刊、紀念品</li>
                  <li>攜眷報名，請填眷屬人數，孩童12歲以上要收費、未滿12歲免費</li>
                </ul>

                <a
                  href='https://docs.google.com/forms/d/e/1FAIpQLScB52fzcXtShW_qA5zEMU9RuZ1D5BSPUjGMWMVW_Hoj3B23ZQ/viewform?usp=pp_url&entry.1494265983=%E4%B8%80%E8%88%AC%E5%A0%B1%E5%90%8D&entry.2122814733=900'
                  className='btn btn-primary'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  立即報名
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PricingCardOne
