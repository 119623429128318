import React from 'react'

class Preloader extends React.Component {
  render() {
    return (
      <div className='preloader'>
        <div className='loader'></div>
      </div>
    )
  }
}

export default Preloader
