import React from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'

class MainBanner extends React.Component {
  state = {
    isOpen: false,
  }

  openModal = () => {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <React.Fragment>
        <ModalVideo
          channel='custom'
          url={require('../../assets/video/promo-video.mp4')}
          isOpen={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
        />

        <div className='main-banner item-bg1'>
          <div className='d-table'>
            <div className='d-table-cell'>
              <div className='container'>
                <div className='row'>
                  <div className='col-12 col-lg-7'>
                    <div className='main-banner-content'>
                      <p>
                        歡迎<span>系友</span>回家
                      </p>
                      <h1>
                        臺灣大學<span>資訊管理系</span> <br />
                        <b>3</b>
                        <b>0</b>
                        <b>週</b>
                        <b>年</b>
                        系慶
                      </h1>

                      <ul>
                        <li>
                          <a
                            href='https://g.page/lamaree-wedding?share'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='text-decoration-none text-white'
                          >
                            <i className='icofont-compass'></i> 水源會館
                          </a>
                        </li>
                        <li>
                          <i className='icofont-calendar'></i> 2022/01/15 15:00-20:45
                        </li>
                      </ul>

                      <div className='button-box'>
                        <Link to='/signup' className='btn btn-primary'>
                          立即報名
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault()
                            this.openModal()
                          }}
                          to='#'
                          className='video-btn popup-youtube d-lg-none'
                        >
                          <i className='icofont-ui-play'></i> 觀看宣傳影片
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className='col-5 p-0 d-none d-lg-block'>
                    <video
                      autoPlay
                      playsInline
                      type='video/mp4'
                      preload
                      width='100%'
                      src={require('../../assets/video/promo-video.mp4')}
                      controls
                    ></video>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='shape1'>
            <img src={require('../../assets/images/shapes/1.png')} alt='shape1' />
          </div>

          <div className='shape2 rotateme'>
            <img src={require('../../assets/images/shapes/2.png')} alt='shape2' />
          </div>

          <div className='shape3 rotateme'>
            <img src={require('../../assets/images/shapes/3.png')} alt='shape3' />
          </div>

          <div className='shape4'>
            <img src={require('../../assets/images/shapes/4.png')} alt='shape4' />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default MainBanner
