import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import HomeDefault from './components/pages/HomeDefault'
import Navigation from './components/Navigation/Navigation'
import AboutOne from './components/pages/AboutOne'
import PricingOne from './components/pages/PricingOne'
import ScheduleOne from './components/pages/ScheduleOne'
import NotFound from './components/pages/NotFound'
import Sponsor from './components/pages/Sponsor'
import Seat from './components/pages/Seat'

// Conditionally render Navigation
const renderNavigation = () => {
  if (!(window.location.pathname === '/coming-soon' || window.location.pathname === '/error-404')) {
    return <Navigation />
  }
}

const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      {renderNavigation()}
      <Switch>
        <Route path='/' exact component={HomeDefault} />
        <Route path='/schedule' exact component={ScheduleOne} />
        <Route path='/donate' exact component={AboutOne} />
        <Route path='/signup' exact component={PricingOne} />
        <Route path='/sponsors' exact component={Sponsor} />
        <Route path='/seat' exact component={Seat} />
        <Route path='/error-404' exact component={NotFound} />

        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default AppRouter

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
