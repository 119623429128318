/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import OwlCarousel from 'react-owl-carousel3'
import lax from 'lax.js'
import LaxDiv from '../Shared/LaxDiv'
import { FacebookProvider, EmbeddedPost } from 'react-facebook'
import YouTube from 'react-youtube'

const options = {
  loop: false,
  nav: false,
  dots: true,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true,
  navText: ["<i class='icofont-rounded-left'></i>", "<i class='icofont-rounded-right'></i>"],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
}

class LatestNews extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      'scroll',
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }
  render() {
    const fbPosts = [
      'https://www.facebook.com/permalink.php?story_fbid=162289836121634&id=100070218557365',
      'https://www.facebook.com/100070218557365/videos/1315201915559838/',
      'https://www.facebook.com/100070218557365/videos/577544013507540/',
      'https://www.facebook.com/100070218557365/videos/1596687354007501/',
      'https://www.facebook.com/permalink.php?story_fbid=142148824802402&id=100070218557365',
      'https://www.facebook.com/100070218557365/videos/1058485171588939/',
      'https://www.facebook.com/permalink.php?story_fbid=135838692100082&id=100070218557365',
      'https://www.facebook.com/permalink.php?story_fbid=126317543052197&id=100070218557365',
      'https://www.facebook.com/permalink.php?story_fbid=120793913604560&id=100070218557365',
      'https://www.facebook.com/permalink.php?story_fbid=114223184261633&id=100070218557365',
    ]
    const ytVideos = [
      'NEjkCKHbk5E',
      'hLKybeOVwRU',
      'LKTDkmHqfpE',
      'dbssa0DxBm0',
      'PoFO5yDraLg',
      'wG6ahPmuK1w',
      '1iSYOOO9HZs',
      '_5NBE0g9nwM',
      'KqHaKSxji4w',
      'kJd-MkqER8k',
    ]
    return (
      <section className='blog-area ptb-120 bg-image'>
        <div className='container'>
          <div className='section-title'>
            <span>Info Update!</span>
            <h2>
              最新<b>消息</b>
            </h2>

            <LaxDiv text='Blog' dataPreset='driftLeft' />

            <div className='bar'></div>
          </div>

          <div className='row'>
            <OwlCarousel className='blog-slides owl-carousel owl-theme' {...options}>
              <FacebookProvider appId='996030004311541'>
                {ytVideos.map((ytLink) => (
                  <div className='col-lg-12 col-md-12'>
                    <div className='single-blog-post text-center'>
                      <YouTube
                        videoId={ytLink} // defaults -> null
                        // id={string} // defaults -> null
                        // className={string} // defaults -> null
                        // containerClassName={string} // defaults -> ''
                        opts={{ width: '412' }} // defaults -> {}
                        // onReady={func} // defaults -> noop
                        // onPlay={func} // defaults -> noop
                        // onPause={func} // defaults -> noop
                        // onEnd={func} // defaults -> noop
                        // onError={func} // defaults -> noop
                        // onStateChange={func} // defaults -> noop
                        // onPlaybackRateChange={func} // defaults -> noop
                        // onPlaybackQualityChange={func} // defaults -> noop
                      />
                    </div>
                  </div>
                ))}
                {fbPosts.map((pLink) => (
                  <div key={pLink} className='col-lg-12 col-md-12'>
                    <div className='single-blog-post'>
                      <EmbeddedPost href={pLink} width='auto' />
                    </div>
                  </div>
                ))}
              </FacebookProvider>
            </OwlCarousel>
          </div>
        </div>
      </section>
    )
  }
}

export default LatestNews
