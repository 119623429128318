import React from 'react'
import { Link } from 'react-router-dom'

class Footer extends React.Component {
  render() {
    return (
      <footer className='footer-area'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='single-footer-widget'>
                <h3>臺灣大學管理學院資訊管理學系</h3>
                <span>
                  <i className='icofont-phone'></i> +886-2-33661200
                </span>

                <p className='location'>
                  <i className='icofont-google-map'></i> 臺北市羅斯福路四段一號 臺灣大學管理學院壹號館七樓
                </p>
              </div>
            </div>

            <div className='col-lg-6 col-md-6'>
              <div className='single-footer-widget'>
                <h3>社群連結</h3>
                <p>跟隨最新消息、抽大獎，現在馬上按讚加入臉書粉絲專頁！</p>

                <ul className='social-links'>
                  <li>
                    <a
                      href='https://www.facebook.com/profile.php?id=100070218557365'
                      className='facebook'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='icofont-facebook'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-lg-12'>
              <div className='copyright-area'>
                <div className='logo'>
                  <Link to='/'>
                    <img src={require('../../assets/images/logo.png')} alt='logo' />
                  </Link>
                </div>
                <p>
                  Copyright <i className='icofont-copyright'></i> 2022 NTUIM. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
