import React from 'react'
import { Link } from 'react-router-dom'

class EventSchedulesOne extends React.Component {
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName('tabs_item')
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }

    tablinks = document.getElementsByTagName('li')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace('current', '')
    }

    document.getElementById(tabNmae).style.display = 'block'
    evt.currentTarget.className += 'current'
  }
  render() {
    return (
      <section className='schedule-area bg-image ptb-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='tab'>
                <ul className='tabs active'>
                  <li onClick={(e) => this.openTabSection(e, 'tab1')} className='current'>
                    <Link to='#'>
                      Event Day
                      <span>15 Janunary 2022</span>
                    </Link>
                  </li>
                </ul>

                <div className='tab_content'>
                  <div id='tab1' className='tabs_item'>
                    <ul className='accordion'>
                      <li className='accordion-item'>
                        <div className='accordion-title'>
                          <div className='schedule-info'>
                            <h3>校園巡禮</h3>

                            <ul>
                              <li>
                                <i className='icofont-user-suited'></i> By everyone
                              </li>
                              <li>
                                <i className='icofont-wall-clock'></i> 15:00 集合@管理學院一號館入口、15:30 - 16:30
                                校園巡禮、16:30 - 17:00 前進會場
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li className='accordion-item'>
                        <div className='accordion-title'>
                          <div className='schedule-info'>
                            <h3>專題座談 - 回顧與展望 臺大資管的里程碑與挑戰</h3>

                            <ul>
                              <li>
                                <i className='icofont-user-suited'></i> By
                                孔令傑教授、莊裕澤教授、技嘉科技處長徐昆羿、愛卡拉執行長程世嘉、台科大資管系主任查士朝、微軟亞太物聯網創新中心總經理葉怡君、Dcard共同創辦人暨執行長林裕欽、新北市政府研究發展考核委員會主任委員林豐裕
                              </li>
                              <li>
                                <i className='icofont-wall-clock'></i> 17:00 - 17:50
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li className='accordion-item'>
                        <div className='accordion-title'>
                          <div className='schedule-info'>
                            <h3>開幕致詞</h3>

                            <ul>
                              <li>
                                <i className='icofont-user-suited'></i> By 院長、系主任及老師們
                              </li>
                              <li>
                                <i className='icofont-wall-clock'></i> 18:15 - 18:25
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li className='accordion-item'>
                        <div className='accordion-title'>
                          <div className='schedule-info'>
                            <h3>系史影片回顧及開始用餐</h3>

                            <ul>
                              <li>
                                <i className='icofont-user-suited'></i> By everyone
                              </li>
                              <li>
                                <i className='icofont-wall-clock'></i> 18:25
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li className='accordion-item'>
                        <div className='accordion-title'>
                          <div className='schedule-info'>
                            <h3>臺大資管系友會及EMBA資管學會致詞</h3>

                            <ul>
                              <li>
                                <i className='icofont-user-suited'></i> By 楊立偉理事長、徐嘉聲會長
                              </li>
                              <li>
                                <i className='icofont-wall-clock'></i> 18:45
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li className='accordion-item'>
                        <div className='accordion-title'>
                          <div className='schedule-info'>
                            <h3>臺大資管促進發展協會捐贈儀式</h3>

                            <ul>
                              <li>
                                <i className='icofont-user-suited'></i> By 胡惠森理事長
                              </li>
                              <li>
                                <i className='icofont-wall-clock'></i> 19:20 - 20:00
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li className='accordion-item'>
                        <div className='accordion-title'>
                          <div className='schedule-info'>
                            <h3>大合照</h3>

                            <ul>
                              <li>
                                <i className='icofont-user-suited'></i> By everyone
                              </li>
                              <li>
                                <i className='icofont-wall-clock'></i> 20:30 - 20:45
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>

                      <li className='accordion-item'>
                        <div className='accordion-title'>
                          <div className='schedule-info'>
                            <h3>閉幕</h3>

                            <ul>
                              <li>
                                <i className='icofont-user-suited'></i> By everyone
                              </li>
                              <li>
                                <i className='icofont-wall-clock'></i> 20:45
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='shape1'>
          <img src={require('../../assets/images/shapes/1.png')} alt='shape1' />
        </div>
        <div className='shape2 rotateme'>
          <img src={require('../../assets/images/shapes/2.png')} alt='shape2' />
        </div>
        <div className='shape3 rotateme'>
          <img src={require('../../assets/images/shapes/3.png')} alt='shape3' />
        </div>
        <div className='shape4'>
          <img src={require('../../assets/images/shapes/4.png')} alt='shape4' />
        </div>
      </section>
    )
  }
}

export default EventSchedulesOne
